<template>
  <div id="app1">
    <div >
      <div class="header P_2" @click="goBack">
        <img src="../assets/icon30.png" alt="">
        <span>分析报告</span>
      </div>

      <div class="companyTitle" @click="goDetail">
        <img style="width:3.5rem" src="../assets/nologo@2x.png" alt="">
        <div style="display:flex;justify-content:space-between;flex:1">
          <div>
            <div class="title">{{companyInfo.company}}</div>
            <div class="code">{{companyInfo.enterprise_tax_number}}</div>
            <div class="box PL_1 PT_2" style="background-color:#FFF">
              <div v-if="companyInfo.ent_status == '在营（开业）' " style="margin-right:1.5rem;">
                <img style="width:4.5rem" src="../assets/zaiyeicon@2x.png" alt="">
              </div>
                
              <div class="box" style="flex-wrap:wrap" v-if="enterprise_tags.length > 0">
                <div class="btn2" v-for="(item,index) in enterprise_tags" :key="index">{{item}}</div>
              </div>
            </div>
            <div class="box PL_3 PT_2" style="background-color:#FFF">
              <div class="desc">{{companyInfo.company_desc}}</div> 
              <div v-if="companyInfo.company_desc != ''" class="more">更多</div>
            </div>
          </div>
          <van-icon size="15" class="ML_5 MT_4" color="#A5ABB6" name="arrow" />
        </div>
      </div>
    </div>

    <van-tabs line-width="50%" color="#3943BC" v-model="active">
      <van-tab title="企业投后综合报告">
          <div style="text-align:center;width:100%;background-color:#Fff">
            <div class="title1">企业力度分</div>
            <div class="date" v-if="date.length > 0">-  {{date[0]}} 年{{date[1]}} 月 {{date[2]}} 日更新  -</div>
            <div class="score" style="font-size:3rem">{{score.sum_score ? Number(score.sum_score).toFixed(1) : '暂无'}}</div>
            <div class="txt1">评价指数解读</div>
          </div>
          <div class="scoreList">
            <router-link :to="score.credit_quality_score ? '/creditQua' : ''" class="scoreItem">
              <div class="scoreListTitle">信用质量评分</div>
              <div class="scoreBottom">
                <div class="scoreBottomScore">
                  <span>{{score.credit_quality_score ? Number( score.credit_quality_score).toFixed(1) : '暂无'}}</span>
                  <span v-if="score.credit_quality_score" style="font-size:1rem">分</span>
                </div>
                <div style="font-size:1rem;display:flex;align-items:center">
                  <span>趋势</span> 
                  <img style="width:1.4rem" src="../assets/pingwen@2x.png" alt="">
                </div>
              </div>
            </router-link>
            <router-link :to="score.viability_score ? '/survivalAbility' : ''" class="scoreItem">
              <div class="scoreListTitle">企业生存能力评分</div>
              <div class="scoreBottom">
                <div class="scoreBottomScore">
                  <span style="color:#59554D">{{score.viability_score ? Number(score.viability_score).toFixed(1) : '暂无'  }}</span>
                  <span v-if="score.viability_score" style="font-size:1rem;color:#59554D">分</span>
                </div>
                <div style="font-size:1rem;display:flex;align-items:center">
                  <span>趋势</span> 
                  <img style="width:1.4rem;margin-left:0.4rem" src="../assets/pingwen@2x.png" alt="">
                </div>
              </div>
            </router-link>
            <router-link :to="score.operational_capability_score ? '/businessCapacity' : ''" class="scoreItem">
              <div class="scoreListTitle">企业经营能力指数</div>
              <div class="scoreBottom">
                <div class="scoreBottomScore">
                  <span style="color: #59554D">{{score.operational_capability_score ? Number( score.operational_capability_score).toFixed(1) : '暂无'}}</span>
                  <span v-if="score.operational_capability_score" style="font-size:1rem;color: #59554D">分</span>
                </div>
                <div style="font-size:1rem;display:flex;align-items:center">
                  <span>趋势</span> 
                  <img style="width:1.4rem;margin-left:0.4rem" src="../assets/pingwen@2x.png" alt="">
                </div>
              </div>
            </router-link>
            <router-link :to="score.transparent_operation_score ? '/businessOpera' : ''" class="scoreItem">
              <div class="scoreListTitle">经营透明指数</div>
              <div class="scoreBottom">
                <div class="scoreBottomScore">
                  <span style="color: #56C054">{{ score.transparent_operation_score ? Number(score.transparent_operation_score).toFixed(1) : '暂无'}}</span>
                  <span v-if="score.transparent_operation_score" style="font-size:1rem;color: #56C054">分</span>
                </div>
                <div style="font-size:1rem;display:flex;align-items:center">
                  <span>趋势</span> 
                  <img style="width:1.4rem;margin-left:0.4rem" src="../assets/pingwen@2x.png" alt="">
                </div>
              </div>
            </router-link>
            <router-link :to="score.ecological_chain_health_score ? '/health' : ''" class="scoreItem">
              <div class="scoreListTitle">生态链健康指数</div>
              <div class="scoreBottom">
                <div class="scoreBottomScore">
                  <span style="color: #56C054">{{score.ecological_chain_health_score ? Number(score.ecological_chain_health_score).toFixed(1) : '暂无'}}</span>
                  <span v-if="score.ecological_chain_health_score" style="font-size:1rem;color: #56C054">分</span>
                </div>
                <div style="font-size:1rem;display:flex;align-items:center">
                  <span>趋势</span> 
                  <img style="width:1.4rem;margin-left:0.4rem" src="../assets/pingwen@2x.png" alt="">
                </div>
              </div>
            </router-link>
            <router-link :to="score.competition_score ? '/compete' : ''" class="scoreItem">
              <div class="scoreListTitle">同业竞争指数</div>
              <div class="scoreBottom">
                <div class="scoreBottomScore">
                  <span style="color: #FF0000; ">{{score.competition_score ? Number(score.competition_score).toFixed(1) : '暂无' }}</span>
                  <span v-if="score.competition_score" style="font-size:1rem;color: #FF0000">分</span>
                </div>
                <div style="font-size:1rem;display:flex;align-items:center">
                  <span>趋势</span> 
                  <img style="width:1.4rem;margin-left:0.4rem" src="../assets/pingwen@2x.png" alt="">
                </div>
              </div>
            </router-link>
          </div>
      </van-tab>
      <van-tab title="人力资源报告">
         <van-empty  style="background:#FFF;" description="- 人力资源报告服务未激活 -" />
      </van-tab>
    </van-tabs>



    <!-- <div style="text-align:center;width:100%;background-color:#Fff">
      <div class="title1">企业综合能力评价指数</div>
      <div class="date">- 2022年9月18日更新 -</div>
      <div class="score">{{score.sum_score ? Number(score.sum_score).toFixed(1) : 0}}</div>
      <div class="txt1">评价指数解读</div>
    </div> -->
    <!-- <div  id="main" style="width: 100%; height: 20rem;background-color:#FFF"></div> -->
    <div style="height:5rem;background-color:#fff;"></div>
    
    
    <div class="fixBtn" > 
      <router-link to="/threeTable" class="btn" >三表</router-link>
      <router-link to="/companyReport" class="btn MLR_1" >最近更新</router-link>
      <router-link to="/creditQua" class="btn" >力度分</router-link>
    </div>
  </div>
</template>
<script>
import * as echarts from 'echarts';
import {mapState,mapActions} from "vuex"
import { Toast } from 'vant';
export default {
  data(){
    return {
      companyInfo:{},
      score:{},
      enterprise_tags:[],
      active:"",
      date:[]
    }
  },
  mounted(){
    this.getDetail()
  },
  computed:{
    ...mapState({
      reportItem:  (state) => state.reportItem,
    }),
  },
  methods:{
    ...mapActions(["getScore"]),
    goDetail(){
      this.$router.push({path: "/companyInfo",query:{
        company:this.companyInfo.company,
        uniformCreditCode:this.companyInfo.enterprise_tax_number
      }})
    },
    // 雷达图
    initEacharts(arr) {
      var myChart = echarts.init(document.getElementById('main'))
      var option;
        option = {
        radar: {
           indicator: [
              { name: '信用质量评分', max: 1000 },
              { name: '生产能力评分', max: 1000 },
              { name: '企业透明指数', max: 1000 },
              { name: '生态链健康指数', max: 1000 },
              { name: '同业竞争评分', max: 1000 },
              { name: '经营能力评分', max: 1000 }
          ]
        },
        series: [ {
          name: 'Budget vs spending',
          type: 'radar',
          data: [
            {
              value: arr,
              name: 'Actual Spending'
            }
        ]
        }]
      };
      myChart.setOption(option); 
    },
    goCreditQua(){
      this.$router.push("/creditQua")
    },
    getDetail(){
       this.getScore({
        reqType: "login", //请求类型
        company: this.reportItem.company, //公司
        uniformCreditCode: this.reportItem.enterprise_tax_number //税号
       }).then((res)=>{
        if(res.data.companyInfo.enterprise_tags){
          this.enterprise_tags = JSON.parse(res.data.companyInfo.enterprise_tags) 
        }
        this.companyInfo = res.data.companyInfo
        this.score = res.data.score
        let arr = res.data.score.add_time ? res.data.score.add_time.split(" ") : []
        let date = arr[0].split("-")
        this.date = date
        console.log(res)
        this.$store.commit("SET_SCORE_ITEM",res.data.score)
        // let {credit_quality_score,viability_score,transparent_operation_score,ecological_chain_health_score,competition_score,operational_capability_score} = res.data.score
        // let arr = [credit_quality_score,viability_score,transparent_operation_score,ecological_chain_health_score,competition_score,operational_capability_score].map(Number)
        // this.initEacharts(arr)
       })
    },
    goBack(){
      this.$router.push("/index")
    },
  },
}
</script>
<style lang="scss" scoped>
.app1{
  background-color: #fff;
  padding:2rem 1rem
 }
 .scoreList{
    background:#fff;
    display: flex;
    justify-content: space-between;
    padding:1rem 1.5rem;
    flex-wrap: wrap;
  .scoreItem{
    width:45%;
    margin-bottom:2.5rem;
  }
  .scoreListTitle{
    font-size: 1.2rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #59554D;
  } 
  .scoreBottom{
    display:flex;
    align-items: baseline;
    margin-top:0.5rem;
  }
  .scoreBottomScore{
    font-size: 1.5rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FF0000;
    margin-right:1rem;
  }
 } 
 

 
.code{
  font-size: 1rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #898989;
  background:#fff;
  padding-left:0.5rem;
}
.btn1{
  width: 7rem;
  height:2rem;
  text-align: center;
  background: #FFFFFF;
  border-radius: 0.3rem;
  border: 3px solid #49C880;
  color:#49C880;
  margin-right:0.5rem;
  padding:0 0.1rem;
}
.box{
  display: flex;
  align-items: center;
  
}
.btn2{
  margin-right:0.5rem;
  margin-bottom:0.5rem;
  background: #E9ECFB;
  border-radius: 0.2rem;
  color:#0572EC;
  padding:0.3rem 0.5rem ;
}
.desc{
  width:85%;
  margin-left:1rem;
  display:block;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
  
}
.more{
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FF4100;
     
    display: inline-block;
}
.line{
  width:100%;
  border:1rem solid #ddd;
}
.container{
  border-bottom:0.5rem solid #F7F7F7
}
.title1{
  font-size: 1.2rem;
font-family: PingFangSC-Semibold, PingFang SC;
font-weight: 600;
color: #35394C;
// margin-top:3rem;
padding-top:3rem;
}
.date{
  font-size: 0.9rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #9D9D9E;
margin:0.4rem 0;
}
.score{
  font-size: 5rem;
font-family: Arial-BoldMT, Arial;
font-weight: normal;
color: #FF4100;
}
.txt1{
  font-size: 1;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #35394C;
  padding: 0.3rem 0;
}
.fixBtn{
  position: fixed;
  bottom:0;
  width:100%;
  background-color:#fff;
  padding:1rem ;
  box-shadow: 0px -2px 8px 0px rgba(188,188,188,0.5);
  display: flex;
  justify-content: center;
  .btn{
     width:25rem;
    height: 3.5rem;
    line-height: 3.5rem;
    background: #151B62;
    color:#fff;
    border-radius: 1rem;
    font-size: 1.2rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
  }
}
</style>